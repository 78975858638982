import { Button } from '@design-system';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { LayoutTheme } from '../../Layout/types';

interface Props {
  isFullScreen?: boolean;
  header?: string;
  hideHeader?: boolean;
  children: ReactNode;
  theme?: LayoutTheme;
  onClose?: () => void;
}

export const DialogWrapper = ({
  children,
  header,
  hideHeader = false,
  isFullScreen = false,
  onClose,
  theme = 'light',
}: Props) => {
  return (
    <div
      className={classNames(
        'relative mx-auto flex w-full flex-col overflow-hidden bg-white outline-0',
        isFullScreen
          ? 'h-full max-w-full'
          : 'max-h-[calc(100%-12vmin)] rounded-t-lg md:max-w-md md:rounded-lg',
        `theme-${theme}`,
      )}
    >
      {!hideHeader ? (
        <div className="sticky left-0 right-0 top-0 z-50 flex w-full items-center justify-between gap-3 bg-white px-6 py-4">
          <span className="w-10" />
          {header ? (
            <p className="w-full text-center font-bold first-letter:uppercase">
              {header}
            </p>
          ) : null}
          <Button
            hideLabel
            icon="ic_close"
            label="Close"
            onClick={onClose}
            size="sm"
            variant="tertiary"
          />
        </div>
      ) : null}

      <div
        className="mx-auto h-full w-full overflow-x-hidden overflow-y-scroll no-scrollbar"
        style={{
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        {children}
      </div>
    </div>
  );
};
